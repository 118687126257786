import TCenter from './TCenter'
import config from './config'
// import VueResource from 'vue-resource'
// Vue.use(VueResource)
// 

function postRequest (url, data, success, fail) {
  if (data == null) data = {}

  // data.token = config.token
  // data.openid = config.openid
  data.appcode = config.appcode

  console.log('postRequest', url, data)

  TCenter.Vue.http.post(config.API_URL + url, data).then(result => {
    console.log(url + ' RESULT', result)
    if (result.data && parseInt(result.data.status) === 1) {
      typeof success === 'function' && success(result.data)
    } else {
      var msg = 'Something error \n' + JSON.stringify(result.data)
      if (result.data != null && result.data.message != null && result.data.message.length > 0) {
        msg = result.data.message
      }
      if (result.data && result.data.data && result.data.data.message != null && result.data.data.message.length > 0) {
        msg = result.data.data.message
        if (msg === '身份过期，请重新登录') {
          msg = '用户信息过期，请重新进入页面'
          console.log('go RELOGIN')
          // getApp().autoLogin(function () {
          //   that.postRequest(url, data, success, fail)
          // }, true)
          return
        }
      }
      typeof fail === 'function' && fail(msg)
    }
  }, response => {
    console.log('response ERROR', response)
    typeof fail === 'function' && fail(response)
  })
}

export default { postRequest }
