class config {
  static getInstance () {
    if (!config.instance) {
      config.instance = new config()
    }
    return config.instance
  }
}

config.config = {
  API_URL: 'https://meizi.hosti4g.com/open/api/index/',
  appcode: 'apcs'
}

export default config.config