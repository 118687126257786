<template>
  <div class="home">

    <div class="freebirdFormviewerViewFormContentWrapper">
      <div class="freebirdFormviewerViewFormBanner freebirdHeaderMast"></div>

      <div class="freebirdFormviewerViewCenteredContent">
        <!-- <form
          action="https://docs.google.com/forms/d/e/1FAIpQLSeSsmmnz_RhEt2XheIfUSVCkwKDG7igP7Y-01DZ6UcYg7lpPw/formResponse"
          target="_self" method="POST" id="mG61Hd"> -->
        <div class="freebirdFormviewerViewFormCard exportFormCard">
          <div class="freebirdFormviewerViewAccentBanner freebirdAccentBackground"></div>
          <div class="freebirdFormviewerViewFormContent">
            <div class="freebirdFormviewerViewNoPadding">
              <div class="freebirdFormviewerViewHeaderHeader exportHeader">
                <div class="freebirdFormviewerViewHeaderTitleRow">
                  <div class="freebirdFormviewerViewHeaderTitle exportFormTitle freebirdCustomFont" dir="auto"
                    role="heading" aria-level="1">報名成功</div>
                </div>
                <div class="freebirdFormviewerViewResponseConfirmationMessage" style="font-size:14px;">
                  <br />
                  <p>恭喜你成功報名『<b>{{showTitle}}</b>』活動，客服會儘快用 Email 跟你聯繫提供活動信息。敬請留意你的郵箱信息。</p>
                  <br />
                  <!-- <div v-if="needPay">
                    <p>如果選擇信用卡付款的朋友，請點擊這個鏈接進行支付：<a
                        href="https://www.petersotraining.com/payment2">https://www.petersotraining.com/payment2</a>
                    </p>
                    <br />
                    <p>如果已經支付則不需要理會以上這個鏈接。</p>
                    <br />
                  </div> -->
                  <p>稍後會有專員致電通知閣下之活動詳情及繳款情況。</p>
                </div>
              </div>
            </div>
            <div class="freebirdFormviewerViewItemList" role="list">

            </div>
            <!-- <div class="freebirdFormviewerViewNavigationNavControls" jscontroller="lSvzH"
                jsaction="rcuQ6b:npT2md;JIbuQc:Gl574d(QR6bsb),V3upec(GeGHKb),HiUbje(M2UYVd),NPBnCf(OCpkoe);"
                data-shuffle-seed="-1572675793233734343" data-should-execute-invisible-captcha-challenge="false"
                data-is-receipt-checked="false">
                <div class="freebirdFormviewerViewNavigationButtonsAndProgress">
                  <div class="freebirdFormviewerViewNavigationButtons">
                    <div role="button"
                      class="quantumWizButtonEl quantumWizButtonPaperbuttonEl quantumWizButtonPaperbuttonFlat quantumWizButtonPaperbuttonDark quantumWizButtonPaperbutton2El2 freebirdFormviewerViewNavigationSubmitButton"
                      jscontroller="VXdfxd"
                      jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc(preventMouseEvents=true|preventDefault=true); touchcancel:JMtRjd;"
                      jsshadow="" jsname="M2UYVd" aria-disabled="false" tabindex="0">
                      <div class="quantumWizButtonPaperbuttonRipple exportInk" jsname="ksKsZd"></div>
                      <div class="quantumWizButtonPaperbuttonFocusOverlay exportOverlay"></div><span jsslot=""
                        class="quantumWizButtonPaperbuttonContent"><span
                          class="quantumWizButtonPaperbuttonLabel exportLabel">提交</span></span>
                    </div>
                  </div>
                </div>
                <div class="freebirdFormviewerViewNavigationPasswordWarning">切勿通过 Google 表单提交密码。</div>
              </div> -->
            <!-- <input type="hidden" name="fvv" value="1"><input type="hidden" name="draftResponse" value="[null,null,&quot;-1572675793233734343&quot;]
"><input type="hidden" name="pageHistory" value="0"><input type="hidden" name="fbzx" value="-1572675793233734343"> -->
          </div>
        </div>
        <!-- </form> -->
      </div>
    </div>

    <!-- <div class="flex-align-con">
      <img src="http://meiziopen.fpv.im/company/apcs/imgs/kllogogoldblack.png" style="width:88px;height:100px;"
        alt="APCS LOGO" />
      <div class="title-text">報名表</div>
    </div>

    <div v-if="isWeixin" class="wxtip">
      <img src="@/assets/wxtip.png" style="margin-right:5px;" width="180px;" alt="">
    </div> -->

    <div wechat-title="恭喜你，報名成功"></div>
  </div>
</template>

<script>
// @ is an alias to /src
// import OrderItem from '@/components/OrderItem.vue'
import UserModel from './../model/UserModel'
import PayPal from 'vue-paypal-checkout'
import { Base64 } from 'js-base64'

export default {
  name: 'result',
  components: {
    // OrderItem,
    PayPal
  },
  data () {
    return {
      showTitle: '',
      needPay: false
    }
  },
  computed:
  {
    isWeixin () {
      var ua = navigator.userAgent
      var isWeixin = !!/MicroMessenger/i.test(ua)
      return isWeixin
    }
  },
  methods: {

  },
  watch: {
  },
  mounted () {
    this.$nextTick(function () {
      this.showTitle = this.$route.query.title
      this.needPay = this.$route.query.needPay
    })
  },
}
</script>


<style>
@import "../assets/google.css";

#app {
  padding: 0px !important;
  max-width: none !important;
  background-color: rgb(255, 240, 217);
}

.home {
  padding: 0px;
}

.flex-align-con {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.title-text {
  margin-left: 5px;
  font-weight: 300;
  font-size: 41px;
  line-height: 25px;
  color: #000;
}

.select-item {
  margin-top: 10px;
}

.hairline {
  height: 1px;
  opacity: 0.5;
  background: #bebebe;
  margin: 20px -20px;
}

.black-title {
  margin: 0px -17px;
  margin-bottom: 20px;
  background: #9a9a9a;
  color: white;
  padding: 5px 20px;
}

.ivu-form-item {
  margin-bottom: 10px !important;
}

.ivu-form .ivu-form-item-label {
  font-size: 15px !important;
  line-height: 20px !important;
}

.graytip {
  font-size: 12px;
  color: #9a9a9a;
}

.wxtip {
  background: rgba(0, 0, 0, 0.8);
  text-align: right;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  /* display: none; */
}

.ivu-form .ivu-checkbox-group-item {
  display: block !important;
}

.ivu-form .ivu-radio-group-item {
  display: block !important;
  word-break: break-all;
  line-height: 32px;
  margin-left: 5px;
}
</style>
