<template>
  <div class="home">

    <div class="freebirdFormviewerViewFormContentWrapper">
      <div class="freebirdFormviewerViewFormBanner freebirdHeaderMast"></div>

      <div class="freebirdFormviewerViewCenteredContent">
        <!-- <form
          action="https://docs.google.com/forms/d/e/1FAIpQLSeSsmmnz_RhEt2XheIfUSVCkwKDG7igP7Y-01DZ6UcYg7lpPw/formResponse"
          target="_self" method="POST" id="mG61Hd"> -->
        <div class="freebirdFormviewerViewFormCard exportFormCard">
          <div class="freebirdFormviewerViewAccentBanner freebirdAccentBackground"></div>
          <div class="freebirdFormviewerViewFormContent" style="padding-top:15px;">
            <div class="freebirdFormviewerViewNoPadding">
              <div v-if="referrer==80000001" style="padding:10px;">
                <img src="../assets/80000001.png" width="100%" />
              </div>
              <div class="freebirdFormviewerViewHeaderHeader exportHeader">
                <div class="freebirdFormviewerViewHeaderTitleRow">
                  <div class="freebirdFormviewerViewHeaderTitle exportFormTitle freebirdCustomFont" dir="auto"
                    role="heading" aria-level="1">{{crouseInfo.title?(crouseInfo.title+'活動鏈接生成'):'活動鏈接生成'}}</div>
                </div>
              </div>
            </div>
            <div class="freebirdFormviewerViewItemList" role="list">

              <div>
                <div class="select-item flex-align-con" style="flex-wrap:wrap">
                  <div style="float:left;">活動種類 *</div>
                  <div style="margin-left:10px;flexGrow:1">
                    <Select size="large" v-model="select1" @on-change="selectChange($event,1)">
                      <Option v-for="item in list1" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                  </div>
                </div>
                <div class="select-item flex-align-con" style="flex-wrap:wrap">
                  <div style="float:left;">活動名稱 *</div>
                  <div style="margin-left:10px;margin-top:5px;flexGrow:1">
                    <Select size="large" v-model="crouse_id" @on-change="selectChange($event,2)">
                      <Option v-for="item in list2" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                  </div>
                </div>
                <!-- <div class="select-item flex-align-con">
                  <div>活動日期 *</div>
                  <div style="margin-left:10px;flexGrow:1">
                    <Select size="large" v-model="select3">
                      <Option v-for="item in list3" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                  </div>
                </div> -->
              </div>

              <!-- <div class="hairline"></div>

              <div v-if="userid==0||userid=='0'">
                <div style="font-size:18px;">你是嘉霖財智的收費課學員嗎？</div>
                <div style="font-size:12px;">例如：法商落地課、稅商思維開大單、生命密碼……等活動</div>

                <div style="margin-top:10px;" class="flex-align-con">
                  如果是，請
                  <Button @click="getProfileClick" type="primary" size="large" style="margin-left:10px;">獲取學員資料</Button>
                </div>

                <div v-if="orderList&&orderList.length>0">
                  <div class="hairline"></div>
                  <div style="margin-left:5px;margin-bottom:10px;">Orders</div>
                  <div v-for="(item, index) in orderList" :key="index">
                    <Card>
                      <p slot="title">Order #{{item.orderid}}</p>
                      <p>報名活動：{{item.title}}</p>
                      <p>老師：{{item.crouse_teacher}}</p>
                      <p>時間：{{item.crouse_date}}</p>
                      <p>費用：HK$ {{parseInt(item.needprice).toLocaleString()}}</p>
                      <div v-if="item.payed==1||item.payed=='1'">
                        <Tag color="success">已支付</Tag>
                      </div>
                      <div v-else style="margin-top:10px;">
                        <PayPal :amount="item.needprice" currency="HKD" :client="credentials"
                          :invoice-number="item.orderid" :button-style="myStyle"
                          @payment-authorized="paymentauthorized($event,item)"
                          notify-url="https://meizi.hosti4g.com/open/api/index/paypal_done">
                        </PayPal>
                      </div>
                    </Card>
                  </div>
                </div>

              </div>

              <div v-else>
                <Button @click="goOrders" type="primary" size="large">
                  <Icon type="md-copy" size="16" /> 查看我的訂單記錄</Button>
              </div>

              <div class="hairline"></div> -->

              <div style="margin-top:20px;">
                <div>請輸入你的電話號碼</div>

                <Form :label-width="110" style="margin-top:7px;">
                  <FormItem label="電話 *">
                    <Input v-model="referrer" size="large" placeholder="輸入手機號" />
                    <div style="color:#666">Tip: 請仔細核對電話號碼是否正確</div>
                  </FormItem>
                </Form>
              </div>

              <div style="margin-top:20px;">
                <Form :label-width="110" style="margin-top:7px;">
                  <FormItem label="Agent Code *">
                    <Input v-model="agentcode" size="large" placeholder="輸入 Agent Code" />
                  </FormItem>
                </Form>
              </div>

              <Button @click="submitClick" type="primary" size="large" long
                style="margin-top:20px;height:45px;">生成</Button>

              <!-- <div style="font-size:13px;margin-top:-10px;">* 必填項，電話可填任意一個地區</div> -->

            </div>
            <!-- <div class="freebirdFormviewerViewNavigationNavControls" jscontroller="lSvzH"
                jsaction="rcuQ6b:npT2md;JIbuQc:Gl574d(QR6bsb),V3upec(GeGHKb),HiUbje(M2UYVd),NPBnCf(OCpkoe);"
                data-shuffle-seed="-1572675793233734343" data-should-execute-invisible-captcha-challenge="false"
                data-is-receipt-checked="false">
                <div class="freebirdFormviewerViewNavigationButtonsAndProgress">
                  <div class="freebirdFormviewerViewNavigationButtons">
                    <div role="button"
                      class="quantumWizButtonEl quantumWizButtonPaperbuttonEl quantumWizButtonPaperbuttonFlat quantumWizButtonPaperbuttonDark quantumWizButtonPaperbutton2El2 freebirdFormviewerViewNavigationSubmitButton"
                      jscontroller="VXdfxd"
                      jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc(preventMouseEvents=true|preventDefault=true); touchcancel:JMtRjd;"
                      jsshadow="" jsname="M2UYVd" aria-disabled="false" tabindex="0">
                      <div class="quantumWizButtonPaperbuttonRipple exportInk" jsname="ksKsZd"></div>
                      <div class="quantumWizButtonPaperbuttonFocusOverlay exportOverlay"></div><span jsslot=""
                        class="quantumWizButtonPaperbuttonContent"><span
                          class="quantumWizButtonPaperbuttonLabel exportLabel">提交</span></span>
                    </div>
                  </div>
                </div>
                <div class="freebirdFormviewerViewNavigationPasswordWarning">切勿通过 Google 表单提交密码。</div>
              </div> -->
            <!-- <input type="hidden" name="fvv" value="1"><input type="hidden" name="draftResponse" value="[null,null,&quot;-1572675793233734343&quot;]
"><input type="hidden" name="pageHistory" value="0"><input type="hidden" name="fbzx" value="-1572675793233734343"> -->
          </div>
        </div>
        <!-- </form> -->
      </div>

    </div>

    <div v-if="createdLink.length>10" class="freebirdFormviewerViewCenteredContent" style="margin-bottom:60px;">
      <div class="cus-form">
        <Form>
          <div class="cus-form-item">
            <div>
              <div>推薦鏈接已生成：</div>
              <div>
                <div style="margin-top:10px;word-break:break-all"><a :href="createdLink">{{createdLink}}</a></div>
                <Button @click="copyClick" type="default" long style="width:100px;margin-top:10px;">Copy</Button>
              </div>
            </div>
          </div>

        </Form>
      </div>

    </div>

    <!-- <div class="flex-align-con">
      <img src="http://meiziopen.fpv.im/company/apcs/imgs/kllogogoldblack.png" style="width:88px;height:100px;"
        alt="APCS LOGO" />
      <div class="title-text">報名表</div>
    </div>

    <div v-if="isWeixin" class="wxtip">
      <img src="@/assets/wxtip.png" style="margin-right:5px;" width="180px;" alt="">
    </div> -->

    <div v-wechat-title="theTitle"></div>
  </div>
</template>

<script>
// @ is an alias to /src
// import OrderItem from '@/components/OrderItem.vue'
import UserModel from './../model/UserModel'
import PayPal from 'vue-paypal-checkout'
import { Base64 } from 'js-base64'
import config from './../model/config'

export default {
  name: 'enroll',
  components: {
    // OrderItem,
    PayPal
  },
  data () {
    return {
      credentials: {
        sandbox: 'Aae55JBXqJIpMAkhJTdvhuTZYsbvSs2j4-yWf26ev0lyrz1iCm0zGbIHq6EkaZFGEfkIjIWuhRw-KUSN',
        production: 'AfWCzYCHrG3ykBTRHLomSbmy8o4mY8wBi4nwt9fREZEUOok_5Bxf7luFUOSwpqih6tiXODJREDnPJy9O'
      },
      myStyle: {
        size: 'medium',
        shape: 'pill',
        color: 'gold'
      },
      orgData: [],
      list1: [],
      list2: [],
      list3: [],
      select1: '',
      crouse_id: '',
      select3: '',
      referrer: '',
      referrername: '',
      wechat: '',
      whatsapp: '',
      tags: '',
      name: '',
      phone1: '',
      phone2: '',
      phone3: '',
      search_phone1: '',
      search_phone2: '',
      search_phone3: '',
      email: '',
      userid: '0',
      price: 0,
      openProfile: false,
      orderList: [],
      crouseInfo: {},
      formwhere: '',
      formwhereother: '',
      isReferrer: false,
      theTitle: '活動報名表',
      justpayed: false,
      extReferrerPhone: null,
      createdLink: '',
      agentcode: ''
    }
  },
  computed:
  {
    isWeixin () {
      var ua = navigator.userAgent
      var isWeixin = !!/MicroMessenger/i.test(ua)
      return isWeixin
    }
  },
  methods: {
    paymentauthorized (e, order) {
      this.goOrders()
      // console.log('paymentauthorized', e, order)
      // this.$Message.info({
      //   content: 'Saving...',
      //   duration: 0
      // });
      // UserModel.setOrderPayed(order.orderid, e, (res) => {
      //   this.$Message.destroy()
      //   this.$Message.info({
      //     content: 'Loading...',
      //     duration: 0
      //   });
      //   this.getOrdersBySearch()
      // }, (err) => {
      //   this.$Message.destroy()
      //   this.$Message.error({
      //     content: err || 'Something error'
      //   })
      //   console.log('err', err)
      // })
    },
    goOrders () {
      this.$router.push({ name: 'orders', query: { phone1: this.phone1 || this.search_phone1, phone2: this.phone2 || this.search_phone2, phone3: this.phone3 || this.search_phone3 } })
    },
    getOrdersBySearch () {
      UserModel.getOrdersByPhone(this.search_phone1, this.search_phone2, this.search_phone3, (res) => {
        this.$Message.destroy()
        this.orderList = res
        console.log('res', res)
      }, (err) => {
        this.openProfile = true
        this.$Message.destroy()
        this.$Message.error({
          content: err || 'Something error'
        })
        console.log('err', err)
      })

    },
    searchPhoneProfile () {

      this.$Message.info({
        content: 'Loading...',
        duration: 0
      })

      UserModel.getUserInfoByPhone(this.search_phone1, this.search_phone2, this.search_phone3, (res) => {
        this.$Message.destroy()
        console.log('res', res)

        res = res[0]

        this.name = res.name
        this.phone1 = res.phone
        this.phone2 = res.phone2
        this.phone3 = res.phone3
        this.email = res.email
        // this.tags = res.tag // .split(',')
        this.userid = res.user_id

        this.getOrdersBySearch()
      }, (err) => {
        this.openProfile = true
        this.$Message.destroy()
        this.$Message.error({
          content: err || 'Something error'
        })
        console.log('err', err)
      })
    },
    getProfileClick () {
      this.openProfile = true
      this.search_phone1 = this.search_phone2 = this.search_phone3 = ''
    },
    copyClick () {
      this.$copyText(this.createdLink).then((e) => {
        this.$Message.success('鏈接已複製')
      }, function (e) {
        this.$Message.error('複製錯誤，請手動選擇複製')
      })
    },
    submitClick (e) {


      if (!this.crouse_id || this.crouse_id.length == 0) {
        this.$Message.error('請選擇活動')
        return
      } else if (this.referrer.length < 8) {
        this.$Message.error('請輸入正確的電話')
        return
      }

      var appcode = config.appcode
      // if (appcode == 'mmts' && this.select1 == 5) {
      //   appcode = 'jsg'
      // }

      this.createdLink = 'https://f.hosti4g.com/e/' + appcode + '/' + this.crouse_id + '/' + Base64.encode(this.referrer) + '/' + this.agentcode

    },
    selectChange (e, level) {
      this.price = 0
      if (level == 1) {
        this.list3 = []
        this.crouse_id = ''
        this.crouseInfo = {}
        this.select3 = ''
        for (var item of this.orgData) {
          if (item.s_id == e) {
            var arr = []
            for (var lesson of item.lesson) {
              arr.push({
                label: lesson.title,
                value: lesson.l_id
              })
            }
            this.list2 = arr
            break
          }
        }
      } else if (level == 2) {
        this.select3 = ''
        for (var item of this.orgData) {
          if (item.s_id == this.select1) {
            for (var lesson of item.lesson) {
              if (lesson.l_id == e) {
                this.price = parseFloat(lesson.price)
                var arr = []
                for (var dd of lesson.date) {
                  var lt = dd.time.split('/')[0]
                  lt = lt.split(' ')[0]
                  console.log('lt', lt)
                  if (lt.length >= 8 && lt.indexOf('-') > 3 && lt.split('-').length == 3) {
                    var da = new Date(Number(lt.split('-')[0]), Number(lt.split('-')[1]) - 1, Number(lt.split('-')[2]));
                    console.log('da', da)
                    if (da.getTime() < (new Date).getTime() - 24 * 60 * 60 * 1000) {
                      continue
                    }
                  }
                  arr.push({
                    label: dd.time + ' - ' + dd.teacher,
                    value: dd.time + ' - ' + dd.teacher
                  })
                }

                this.list3 = arr
                if (this.list3.length == 1) {
                  this.select3 = this.list3[0].value
                }
                break
              }
            }
            break
          }
        }
      }
    }
  },
  watch: {
    crouse_id: function (val) {
      for (var s of this.orgData) {
        for (var l of s.lesson) {
          console.log(l.l_id, this.$route.params.lessonid);
          if (l.l_id == val) {
            this.crouseInfo = l
            this.theTitle = l.title + '報名表'
            if (this.crouseInfo.cover && this.crouseInfo.cover.indexOf('meiziopen.fpv.im') > 0 && this.crouseInfo.cover.indexOf('!750w') == -1) {
              this.crouseInfo.cover = this.crouseInfo.cover += '!750w'
            }
            return
          }
        }
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      console.log('$route.params', this.$route.params)
      console.log('$route.query', this.$route.query)

      if (this.$route.query.p == 1) {
        this.justpayed = true
      } else {
        this.justpayed = false
      }

      if (this.$route.params.appcode && this.$route.params.appcode.length > 2) {
        config.appcode = this.$route.params.appcode
        // if (config.appcode == 'jsg') {
        //   config.appcode = 'mmts'
        // }
      }

      if (this.$route.params.referrer && this.$route.params.referrer.length > 2) {
        this.isReferrer = true
        this.referrer = Base64.decode(this.$route.params.referrer)
        if (this.referrer == '80000001') {
          this.referrername = '01商學院'
          this.extReferrerPhone = '60807824'
        }
      } else {
        this.isReferrer = false
      }

      this.$Message.info({
        content: 'Loading...',
        duration: 0
      });
      UserModel.loadClasses((res) => {
        this.$Message.destroy()
        this.orgData = res
        var arr = []
        for (var item of this.orgData) {
          arr.push({
            label: item.title,
            value: item.s_id
          })
        }
        this.list1 = arr
        this.list2 = []
        this.list3 = []

        if (this.orgData.length == 1) {
          this.select1 = this.orgData[0].s_id
          this.selectChange(this.select1, 1)

          if (this.orgData[0].lesson.length == 1) {
            this.crouse_id = this.orgData[0].lesson[0].l_id
            this.selectChange(this.crouse_id, 2)
          }
        }



        if (this.$route.params.lessonid) {
          for (var s of this.orgData) {
            for (var l of s.lesson) {
              console.log(l.l_id, this.$route.params.lessonid);
              if (l.l_id == this.$route.params.lessonid) {
                this.select1 = s.s_id
                this.selectChange(this.select1, 1)

                this.crouse_id = l.l_id
                this.selectChange(this.crouse_id, 2)
                return
              }
            }
          }
        }
      }, (err) => {
        this.$Message.destroy()
        this.$Message.error({
          content: err || 'Something error'
        })
        console.log('err', err)
      })
    })
  },
}
</script>


<style>
@import "../assets/google.css";

#app {
  padding: 0px !important;
  max-width: none !important;
  background-color: rgb(255, 240, 217);
}

.home {
  padding: 0px;
}

.flex-align-con {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.title-text {
  margin-left: 5px;
  font-weight: 300;
  font-size: 41px;
  line-height: 25px;
  color: #000;
}

.select-item {
  margin-top: 10px;
}

.hairline {
  height: 1px;
  opacity: 0.5;
  background: #bebebe;
  margin: 20px -20px;
}

.black-title {
  margin: 0px -17px;
  margin-bottom: 20px;
  background: #9a9a9a;
  color: white;
  padding: 5px 20px;
}

.ivu-form-item {
  margin-bottom: 10px !important;
}

.ivu-form .ivu-form-item-label {
  font-size: 15px !important;
  line-height: 20px !important;
}

.graytip {
  font-size: 12px;
  color: #9a9a9a;
}

.wxtip {
  background: rgba(0, 0, 0, 0.8);
  text-align: right;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  /* display: none; */
}

.ivu-form .ivu-checkbox-group-item {
  display: block !important;
}

.ivu-form .ivu-radio-group-item {
  display: block !important;
  word-break: break-all;
  line-height: 32px;
  margin-left: 5px;
}

.cus-form {
  /* padding: 10px 10px; */
  margin-bottom: 20px;
}

.cus-form-item {
  margin-top: 14px;
  margin-bottom: 14px;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  padding: 20px;
}

.ivu-form .cus-form-item .ivu-form-item-label {
  display: block;
  float: none;
  text-align: left;
}

.cus-form-item-content {
  margin-top: -10px;
  color: #515a6e;
  font-size: 13px;
}
</style>
