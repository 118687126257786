import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/iview.js'
import VueResource from 'vue-resource'
import iView from 'iview'
import TCenter from './model/TCenter'
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.use(VueResource)
Vue.http.options.emulateJSON = true
// Vue.http.options.credentials = true

Vue.use(require('vue-wechat-title'))

router.beforeEach((to, from, next) => {
  iView.LoadingBar.start()
  // Util.title(to.meta.title)
  next()
})

router.afterEach(() => {
  iView.LoadingBar.finish()
  window.scrollTo(0, 0)
})

TCenter.Vue = Vue
TCenter.rootVue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')