import ym from './YellowModel'

class UserModel {
  static getInstance () {
    if (!UserModel.instance) {
      UserModel.instance = new UserModel()
    }
    return UserModel.instance
  }

  loadClasses (success, fail) {
    ym.postRequest('get_reg_lesson_list', {getall:1}, function (res) {
      success(res.data)
    }, fail)
  }

  regLesson (data, success, fail) {
    ym.postRequest('reg_lesson', data, function (res) {
      success(res.data)
    }, fail)
  }

  getUserInfoByPhone (p1, p2, p3, success, fail) {
    ym.postRequest('get_user_info_by_phone', {
      phone: p1,
      phone1: p1,
      phone2: p2,
      phone3: p3
    }, function (res) {
      success(res.data)
    }, fail)
  }

  getOrdersByPhone (p1, p2, p3, success, fail) {
    ym.postRequest('get_reg_order', {
      phone: p1,
      phone1: p1,
      phone2: p2,
      phone3: p3
    }, function (res) {
      success(res.data)
    }, fail)
  }

  setOrderPayed (orderid, data, success, fail) {
    ym.postRequest('set_order_payed', {
      orderid: orderid,
      order_id: orderid,
      data: JSON.stringify(data)
    }, function (res) {
      success(res.data)
    }, fail)
  }
}


// module.exports.default = this
// module.exports.loadClasses = loadClasses

export default UserModel.getInstance()