class TCenter {
  static getInstance () {
    if (!TCenter.instance) {
      TCenter.instance = new TCenter()
    }
    return TCenter.instance
  }
}

TCenter.config = {}

export default TCenter.config