import Vue from 'vue'
import Router from 'vue-router'
// import Home from './views/Home.vue'
import GoogleForm1 from './views/GoogleForm1.vue'
import GooglePayResult from './views/GooglePayResult.vue'
import CreateLink from './views/CreateLink.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/create/:appcode',
      name: 'create',
      component: CreateLink
    },
    {
      path: '/create/:appcode/:lessonid',
      name: 'create',
      component: CreateLink
    },
    {
      path: '/e/:appcode/:lessonid/:referrer',
      name: 'google1e',
      component: GoogleForm1
    },
    {
      path: '/e/:appcode/:lessonid/:referrer/:agentcode',
      name: 'google1e',
      component: GoogleForm1
    },
    {
      path: '/e/:appcode/:lessonid',
      name: 'google1e',
      component: GoogleForm1
    },
    {
      path: '/e/:appcode',
      name: 'google1e',
      component: GoogleForm1
    },
    {
      path: '/result',
      name: 'result',
      component: GooglePayResult
    }
  ]
})
