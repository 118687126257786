<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  // components: {
  //   HelloWorld
  // }
}
</script>

<style>
html,
body {
  background-color: rgb(255, 240, 217);
}

#app {
  margin-left: auto;
  margin-right: auto;
  max-width: 760px;
  font-family: "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #474747;
  font-size: 16px;
}
</style>
