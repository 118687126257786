<template>
  <div class="home">

    <div class="freebirdFormviewerViewFormContentWrapper">
      <div class="freebirdFormviewerViewFormBanner freebirdHeaderMast"></div>

      <div class="freebirdFormviewerViewCenteredContent">
        <!-- <form
          action="https://docs.google.com/forms/d/e/1FAIpQLSeSsmmnz_RhEt2XheIfUSVCkwKDG7igP7Y-01DZ6UcYg7lpPw/formResponse"
          target="_self" method="POST" id="mG61Hd"> -->
        <div class="freebirdFormviewerViewFormCard exportFormCard">
          <div class="freebirdFormviewerViewAccentBanner freebirdAccentBackground"></div>
          <div class="freebirdFormviewerViewFormContent" style="padding-top:15px;">
            <div class="freebirdFormviewerViewNoPadding">
              <div v-if="referrer == 80000001" style="padding:10px;">
                <img src="../assets/80000001.png" width="100%" />
              </div>
              <div class="freebirdFormviewerViewHeaderHeader exportHeader">
                <div class="freebirdFormviewerViewHeaderTitleRow">
                  <div class="freebirdFormviewerViewHeaderTitle exportFormTitle freebirdCustomFont" dir="auto"
                    role="heading" aria-level="1">{{ crouseInfo.title ? (crouseInfo.title + '報名表') : '活動報名表' }}</div>
                </div>
                <div jsname="F0H8Yc" class="freebirdCommonViewSecurequizSecureQuizBannerContainer"></div>
                <div class="freebirdFormviewerViewHeaderRequiredLegend" aria-hidden="true" dir="auto">
                  *必填，電話可填任意一個地區
                </div>
              </div>
            </div>
            <div class="freebirdFormviewerViewItemList" role="list">
              <div class="freebirdFormviewerViewNumberedItemContainer">
                <div role="listitem" class="freebirdFormviewerViewItemsItemItem" jsname="ibnC6b" jscontroller="RGrRJf"
                  data-item-id="1542750169">
                  <div class="freebirdFormviewerViewItemsItemItemHeader">
                    <div class="freebirdFormviewerViewItemsItemItemTitleContainer">
                      <div class="freebirdFormviewerViewItemsItemItemTitle exportItemTitle freebirdCustomFont"
                        dir="auto"></div>
                    </div>
                  </div>
                  <div class="freebirdFormviewerViewItemsEmbeddedobjectLeft">
                    <div class="freebirdFormviewerViewItemsEmbeddedobjectImageWrapper"><img :src="crouseInfo.cover"
                        class="freebirdFormviewerViewItemsEmbeddedobjectImage" width="100%" title="" alt="">
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="crouseInfo && crouseInfo.content && crouseInfo.content.length > 5"
                class="freebirdFormviewerViewResponseConfirmationMessage" style="font-size:14px;"
                v-html="crouseInfo.content">
              </div>

              <div v-if="crouseInfo.address && crouseInfo.address.length > 0">
                地址：{{ crouseInfo.address }}
              </div>

              <div class="freebirdFormviewerViewNumberedItemContainer">
                <div role="listitem" class="freebirdFormviewerViewItemsItemItem" jsname="ibnC6b" jscontroller="RGrRJf"
                  data-item-id="40789319">
                  <div class="freebirdMaterialHeaderbannerLabelContainer freebirdFormviewerViewItemsSectionheaderBanner"
                    jsname="bLLMxc" role="heading">
                    <div class="freebirdMaterialHeaderbannerLabelTextContainer freebirdSolidBackground">
                      <div
                        class="freebirdMaterialHeaderbannerSectionText freebirdFormviewerViewItemsSectionheaderBannerText freebirdCustomFont freebirdFormviewerViewItemsSectionheaderBasicFont">
                        報名參加請填寫以下資料：</div>
                    </div>
                    <div class="freebirdMaterialHeaderbannerSectionTriangleContainer">
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10 10"
                        preserveAspectRatio="none" class="freebirdMaterialHeaderbannerSectionTriangle">
                        <polygon class="freebirdSolidFill" points="0,0 10,0 0,10"></polygon>
                      </svg>
                    </div>
                  </div>
                  <div jsname="XbIQze" class="freebirdFormviewerViewItemsItemErrorMessage" id="i.err.40789319"
                    role="alert"></div>
                </div>
              </div>

              <div>
                <div class="select-item flex-align-con">
                  <div>活動種類 *</div>
                  <div style="margin-left:10px;flexGrow:1">
                    <Select size="large" v-model="select1" @on-change="selectChange($event, 1)">
                      <Option v-for="item in list1" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                  </div>
                </div>
                <div class="select-item flex-align-con">
                  <div>活動名稱 *</div>
                  <div style="margin-left:10px;flexGrow:1">
                    <Select size="large" v-model="crouse_id" @on-change="selectChange($event, 2)">
                      <Option v-for="item in list2" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                  </div>
                </div>
                <div class="select-item flex-align-con">
                  <div>活動日期 *</div>
                  <div style="margin-left:10px;flexGrow:1">
                    <Select size="large" v-model="select3" @on-change="selectChange($event, 3)">
                      <Option v-for="item in list3" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                  </div>

                </div>
                <div style="width:100%;text-align:right;font-size:14px;color:#515a6e;margin-top:2px;">
                  {{ select3enroll }}
                </div>
              </div>

              <!-- <div class="hairline"></div>

              <div v-if="userid==0||userid=='0'">
                <div style="font-size:18px;">你是嘉霖財智的收費課學員嗎？</div>
                <div style="font-size:12px;">例如：法商落地課、稅商思維開大單、生命密碼……等活動</div>

                <div style="margin-top:10px;" class="flex-align-con">
                  如果是，請
                  <Button @click="getProfileClick" type="primary" size="large" style="margin-left:10px;">獲取學員資料</Button>
                </div>

                <div v-if="orderList&&orderList.length>0">
                  <div class="hairline"></div>
                  <div style="margin-left:5px;margin-bottom:10px;">Orders</div>
                  <div v-for="(item, index) in orderList" :key="index">
                    <Card>
                      <p slot="title">Order #{{item.orderid}}</p>
                      <p>報名活動：{{item.title}}</p>
                      <p>老師：{{item.crouse_teacher}}</p>
                      <p>時間：{{item.crouse_date}}</p>
                      <p>費用：HK$ {{parseInt(item.needprice).toLocaleString()}}</p>
                      <div v-if="item.payed==1||item.payed=='1'">
                        <Tag color="success">已支付</Tag>
                      </div>
                      <div v-else style="margin-top:10px;">
                        <PayPal :amount="item.needprice" currency="HKD" :client="credentials"
                          :invoice-number="item.orderid" :button-style="myStyle"
                          @payment-authorized="paymentauthorized($event,item)"
                          notify-url="https://meizi.hosti4g.com/open/api/index/paypal_done">
                        </PayPal>
                      </div>
                    </Card>
                  </div>
                </div>

              </div>

              <div v-else>
                <Button @click="goOrders" type="primary" size="large">
                  <Icon type="md-copy" size="16" /> 查看我的訂單記錄</Button>
              </div>
              -->

              <div class="hairline"></div>

              <div>
                <div>你的推薦人是</div>

                <Form :label-width="80" style="margin-top:7px;">
                  <FormItem v-if="referrername && referrername.length > 0 || !isReferrer" label="推薦人 ">
                    <Input size="large" v-model="referrername" placeholder="請輸入推薦人姓名" :readonly="isReferrer" />
                  </FormItem>

                  <FormItem v-if="extReferrerPhone && extReferrerPhone.length > 5" label="聯絡電話">
                    <a style="font-size:16px;line-height:40px;" :href="'tel:' + extReferrerPhone">{{
                      extReferrerPhone
                    }}</a>
                  </FormItem>
                  <FormItem v-else-if="referrer || !isReferrer" label="電話 ">
                    <Input v-model="referrer" size="large" placeholder="輸入推薦人的手機號" :readonly="isReferrer" />
                  </FormItem>
                </Form>
              </div>

              <Form v-if="!isReferrer" :label-width="90" style="margin-top:20px;">
                <FormItem label="您從哪裏認識我們? *">
                  <RadioGroup v-model="formwhere">
                    <Radio size="large" label="Facebook"></Radio>
                    <Radio size="large" label="學員介紹"></Radio>
                    <Radio size="large" label="其他"></Radio>
                  </RadioGroup>
                  <!-- <div>（可多選）</div> -->
                </FormItem>
                <FormItem v-if="formwhere == '其他'" label="其他：">
                  <Input size="large" v-model="formwhereother" placeholder="您從哪裏認識我們? " />
                </FormItem>
              </Form>

              <div class="hairline"></div>
              <div class="flex-align-con" v-if="price > 0">
                <span>報名費用：</span><span style="margin-left:10px;font-size:32px;font-weight:300;">HK$
                  {{ parseInt (price).toLocaleString() }}</span>

              </div>
              <div v-if="price > 0" class="hairline"></div>

              <div class="black-title">基本資料：</div>

              <div class="freebirdFormviewerViewHeaderRequiredLegend" style="margin-top:0px;margin-bottom:20px;"
                aria-hidden="true" dir="auto">
                有 * 的是必填資料，電話可填任意一個地區
              </div>

              <div style="margin-left:-10px;">
                <Form :label-width="90">
                  <FormItem label="姓名 *">
                    <Input size="large" v-model="name" placeholder="請輸入姓名" />
                  </FormItem>
                  <FormItem label="電話 * (852)">
                    <Input size="large" v-model="phone2" placeholder="香港電話" type="number" />
                  </FormItem>
                  <FormItem>
                    <Input size="large" v-model="phone1" placeholder="內地電話" type="number" />
                    <span slot="label">
                      <span class="graytip">內地</span> (86)
                    </span>
                  </FormItem>
                  <FormItem>
                    <Input size="large" v-model="phone3" placeholder="澳門電話" type="number" />
                    <span slot="label">
                      <span class="graytip">澳門</span> (853)
                    </span>
                  </FormItem>
                  <FormItem label="郵箱 *">
                    <Input size="large" v-model="email" placeholder="請輸入郵箱" type="email" />
                  </FormItem>
                  <FormItem label="微信號">
                    <Input size="large" v-model="wechat" placeholder="如果有微信號請輸入" />
                  </FormItem>
                  <FormItem label="Whatsapp 號碼">
                    <Input size="large" v-model="whatsapp" placeholder="如果有Whatsapp號碼請輸入" />
                  </FormItem>
                  <!-- <FormItem label="之前報名時的付款方式"> -->
                  <!-- <div v-if="price > 0" class="ivu-form-item-label" style="margin-left:8px;">付款方式：*</div>
                  <RadioGroup v-if="price > 0" v-model="tags">
                    <Radio size="large" label="轉數快：97766600 So Kin Kiu 中國銀行"></Radio>
                    <Radio size="large" label="Payme：97766600 So Kin Kiu"></Radio>
                    <Radio size="large" label="銀行轉帳: 249 521386 888 恆生 So Kin Kiu"></Radio>
                    <Radio size="large" label="銀行轉帳: 012 618 1 0198349 中銀 So Kin Kiu"></Radio>
                    <Radio size="large" label="信用卡：提交表格後會收到信用卡付款鏈接"></Radio>
                    <Radio v-if="justpayed" size="large" label="已支付費用"></Radio>
                  </RadioGroup> -->
                  <!-- <div>（可多選）</div> -->
                  <!-- </FormItem> -->
                </Form>
              </div>

              <!-- <div style="font-size:13px;margin-top:-10px;">* 必填項，電話可填任意一個地區</div> -->

            </div>
            <!-- <div class="freebirdFormviewerViewNavigationNavControls" jscontroller="lSvzH"
                jsaction="rcuQ6b:npT2md;JIbuQc:Gl574d(QR6bsb),V3upec(GeGHKb),HiUbje(M2UYVd),NPBnCf(OCpkoe);"
                data-shuffle-seed="-1572675793233734343" data-should-execute-invisible-captcha-challenge="false"
                data-is-receipt-checked="false">
                <div class="freebirdFormviewerViewNavigationButtonsAndProgress">
                  <div class="freebirdFormviewerViewNavigationButtons">
                    <div role="button"
                      class="quantumWizButtonEl quantumWizButtonPaperbuttonEl quantumWizButtonPaperbuttonFlat quantumWizButtonPaperbuttonDark quantumWizButtonPaperbutton2El2 freebirdFormviewerViewNavigationSubmitButton"
                      jscontroller="VXdfxd"
                      jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc(preventMouseEvents=true|preventDefault=true); touchcancel:JMtRjd;"
                      jsshadow="" jsname="M2UYVd" aria-disabled="false" tabindex="0">
                      <div class="quantumWizButtonPaperbuttonRipple exportInk" jsname="ksKsZd"></div>
                      <div class="quantumWizButtonPaperbuttonFocusOverlay exportOverlay"></div><span jsslot=""
                        class="quantumWizButtonPaperbuttonContent"><span
                          class="quantumWizButtonPaperbuttonLabel exportLabel">提交</span></span>
                    </div>
                  </div>
                </div>
                <div class="freebirdFormviewerViewNavigationPasswordWarning">切勿通过 Google 表单提交密码。</div>
              </div> -->
            <!-- <input type="hidden" name="fvv" value="1"><input type="hidden" name="draftResponse" value="[null,null,&quot;-1572675793233734343&quot;]
"><input type="hidden" name="pageHistory" value="0"><input type="hidden" name="fbzx" value="-1572675793233734343"> -->
          </div>
        </div>
        <!-- </form> -->
      </div>

      <div class="freebirdFormviewerViewCenteredContent" v-if="crouseInfo.question && crouseInfo.question.length > 0">
        <div class="freebirdFormviewerViewNumberedItemContainer" style="margin-left:17px;">
          <div role="listitem" class="freebirdFormviewerViewItemsItemItem" jsname="ibnC6b" jscontroller="RGrRJf"
            data-item-id="40789319">
            <div class="freebirdMaterialHeaderbannerLabelContainer freebirdFormviewerViewItemsSectionheaderBanner"
              jsname="bLLMxc" role="heading">
              <div class="freebirdMaterialHeaderbannerLabelTextContainer freebirdSolidBackground">
                <div
                  class="freebirdMaterialHeaderbannerSectionText freebirdFormviewerViewItemsSectionheaderBannerText freebirdCustomFont freebirdFormviewerViewItemsSectionheaderBasicFont">
                  附加資料：</div>
              </div>
              <div class="freebirdMaterialHeaderbannerSectionTriangleContainer">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10 10" preserveAspectRatio="none"
                  class="freebirdMaterialHeaderbannerSectionTriangle">
                  <polygon class="freebirdSolidFill" points="0,0 10,0 0,10"></polygon>
                </svg>
              </div>
            </div>
            <div jsname="XbIQze" class="freebirdFormviewerViewItemsItemErrorMessage" id="i.err.40789319" role="alert">
            </div>
          </div>
        </div>
      </div>

      <div class="freebirdFormviewerViewCenteredContent" v-if="crouseInfo.question && crouseInfo.question.length > 0">
        <div class="cus-form">
          <Form>
            <div class="cus-form-item" v-for="(item, index) in crouseInfo.question" :key="index">
              <FormItem v-if="item.type == 2">
                <div slot="label"
                  v-html="item.title + (item.required == 1 ? '<span style=\'color:red;font-weight:bold\'> *</span>' : '')">

                </div>
                <div v-if="item.content && item.content.length > 0" class="cus-form-item-content" v-html="item.content">
                </div>
                <Input size="large" v-model="item.fillcontent" placeholder="您的回答" />
              </FormItem>
              <div v-if="item.type == 1&&item.answers.length<=4">
                <div class="ivu-form-item-label"
                  v-html="item.title + (item.required == 1 ? '<span style=\'color:red;font-weight:bold\'> *</span>' : '')">
                </div>
                <div v-if="item.content && item.content.length > 0" class="cus-form-item-content" v-html="item.content">
                </div>
                <RadioGroup v-if="item.answers && item.answers.length > 0 && item.answers[0].length > 0"
                  v-model="item.fillcontent">
                  <Radio v-for="(item2, index2) in item.answers" :key="index2" size="large" :label="item2"></Radio>
                </RadioGroup>
              </div>
              <div v-if="item.type == 1&&item.answers.length>4">
                <div class="ivu-form-item-label"
                  v-html="item.title + (item.required == 1 ? '<span style=\'color:red;font-weight:bold\'> *</span>' : '')">
                </div>
                <Select v-model="item.fillcontent" size="large">
                  <Option v-for="item3 in item.answers" :value="item3" :key="item3">{{ item3 }}</Option>
                </Select>
              </div>
            </div>

          </Form>
        </div>
      </div>

      <div class="freebirdFormviewerViewCenteredContent" style="margin-bottom:50px;">
        <Button @click="submitClick" type="primary" size="large" long style="margin-top:20px;height:45px;">提交</Button>
      </div>
    </div>

    <!-- <div class="flex-align-con">
      <img src="http://meiziopen.fpv.im/company/apcs/imgs/kllogogoldblack.png" style="width:88px;height:100px;"
        alt="APCS LOGO" />
      <div class="title-text">報名表</div>
    </div>

    <div v-if="isWeixin" class="wxtip">
      <img src="@/assets/wxtip.png" style="margin-right:5px;" width="180px;" alt="">
    </div> -->

    <Modal v-model="openProfile" title="獲取學員資料" width="600" @on-ok="searchPhoneProfile">
      <Form :label-width="90">
        <FormItem label="電話 (852)">
          <Input size="large" v-model="search_phone2" placeholder="香港電話" type="number" />
        </FormItem>
        <FormItem>
          <Input size="large" v-model="search_phone1" placeholder="內地電話" type="number" />
          <span slot="label">
            <span class="graytip">內地</span> (86)
          </span>
        </FormItem>
        <FormItem>
          <Input size="large" v-model="search_phone3" placeholder="澳門電話" type="number" />
          <span slot="label">
            <span class="graytip">澳門</span> (853)
          </span>
        </FormItem>
      </Form>

      <div style="font-size:13px;margin-top:15px;margin-left:20px;">電話可填已登記的任意一個地區</div>

    </Modal>

    <div v-wechat-title="theTitle"></div>
  </div>
</template>

<script>
// @ is an alias to /src
// import OrderItem from '@/components/OrderItem.vue'
import UserModel from './../model/UserModel'
import PayPal from 'vue-paypal-checkout'
import { Base64 } from 'js-base64'
import config from './../model/config'

export default {
  name: 'enroll',
  components: {
    // OrderItem,
    PayPal
  },
  data () {
    return {
      credentials: {
        sandbox: 'Aae55JBXqJIpMAkhJTdvhuTZYsbvSs2j4-yWf26ev0lyrz1iCm0zGbIHq6EkaZFGEfkIjIWuhRw-KUSN',
        production: 'AfWCzYCHrG3ykBTRHLomSbmy8o4mY8wBi4nwt9fREZEUOok_5Bxf7luFUOSwpqih6tiXODJREDnPJy9O'
      },
      myStyle: {
        size: 'medium',
        shape: 'pill',
        color: 'gold'
      },
      orgData: [],
      list1: [],
      list2: [],
      list3: [],
      select1: '',
      crouse_id: '',
      select3: '',
      select3enroll: '',
      referrer: '',
      referrername: '',
      wechat: '',
      whatsapp: '',
      tags: '',
      name: '',
      phone1: '',
      phone2: '',
      phone3: '',
      search_phone1: '',
      search_phone2: '',
      search_phone3: '',
      email: '',
      userid: '0',
      price: 0,
      openProfile: false,
      orderList: [],
      crouseInfo: {},
      formwhere: '',
      formwhereother: '',
      isReferrer: false,
      theTitle: '活動報名表',
      justpayed: false,
      extReferrerPhone: null
    }
  },
  computed:
  {
    isWeixin () {
      var ua = navigator.userAgent
      var isWeixin = !!/MicroMessenger/i.test(ua)
      return isWeixin
    }
  },
  methods: {
    paymentauthorized (e, order) {
      this.goOrders()
      // console.log('paymentauthorized', e, order)
      // this.$Message.info({
      //   content: 'Saving...',
      //   duration: 0
      // });
      // UserModel.setOrderPayed(order.orderid, e, (res) => {
      //   this.$Message.destroy()
      //   this.$Message.info({
      //     content: 'Loading...',
      //     duration: 0
      //   });
      //   this.getOrdersBySearch()
      // }, (err) => {
      //   this.$Message.destroy()
      //   this.$Message.error({
      //     content: err || 'Something error'
      //   })
      //   console.log('err', err)
      // })
    },
    goOrders () {
      this.$router.push({ name: 'orders', query: { phone1: this.phone1 || this.search_phone1, phone2: this.phone2 || this.search_phone2, phone3: this.phone3 || this.search_phone3 } })
    },
    getOrdersBySearch () {
      UserModel.getOrdersByPhone(this.search_phone1, this.search_phone2, this.search_phone3, (res) => {
        this.$Message.destroy()
        this.orderList = res
        console.log('res', res)
      }, (err) => {
        this.openProfile = true
        this.$Message.destroy()
        this.$Message.error({
          content: err || 'Something error'
        })
        console.log('err', err)
      })

    },
    searchPhoneProfile () {

      this.$Message.info({
        content: 'Loading...',
        duration: 0
      })

      UserModel.getUserInfoByPhone(this.search_phone1, this.search_phone2, this.search_phone3, (res) => {
        this.$Message.destroy()
        console.log('res', res)

        res = res[0]

        this.name = res.name
        this.phone1 = res.phone
        this.phone2 = res.phone2
        this.phone3 = res.phone3
        this.email = res.email
        // this.tags = res.tag // .split(',')
        this.userid = res.user_id

        this.getOrdersBySearch()
      }, (err) => {
        this.openProfile = true
        this.$Message.destroy()
        this.$Message.error({
          content: err || 'Something error'
        })
        console.log('err', err)
      })
    },
    getProfileClick () {
      this.openProfile = true
      this.search_phone1 = this.search_phone2 = this.search_phone3 = ''
    },
    submitClick (e) {
      var formwhere = this.formwhere == '其他' ? this.formwhereother : this.formwhere
      if (this.select3 == '') {
        this.$Message.error('請選擇報名活動及日期')
        return
      } else if (this.name.length < 2) {
        this.$Message.error('請輸入姓名')
        return
      } else if (this.phone1.length < 8 && this.phone2.length < 8 && this.phone3.length < 8) {
        this.$Message.error('請輸入至少一個正確電話')
        return
      } else if (this.email.length < 4 || this.email.indexOf('@') < 1 || this.email.indexOf('.') == -1) {
        this.$Message.error('請輸入正確的郵箱')
        return
      }
      //  else if (!this.isReferrer && formwhere == '') {
      //   this.$Message.error('請選擇您從哪裏認識我們')
      //   return
      // }
      //  else if (this.tags == '') {
      //   this.$Message.error('請選擇您的付款方式')
      //   return
      // }
      if (this.crouseInfo.question && this.crouseInfo.question.length > 0) {
        for (const item of this.crouseInfo.question) {
          if (item.required == 1) {
            if (!item.fillcontent || item.fillcontent == '' || item.fillcontent.length == 0) {
              this.$Message.error('請完成附加資料「' + item.title + '」')
              return
            }
          }
        }
      }

      this.$Message.info({
        content: 'Loading...',
        duration: 0
      });

      var data = {
        userid: this.userid,
        user_id: this.userid,
        crouse_id: this.crouse_id,
        crouse_date: this.select3.split(' - ')[0],
        crouse_teacher: this.select3.split(' - ')[1],
        referrer: this.referrer,
        referrername: this.referrername,
        name: this.name,
        phone: this.phone1,
        phone1: this.phone1,
        phone2: this.phone2,
        phone3: this.phone3,
        email: this.email,
        wechat: this.wechat,
        whatsapp: this.whatsapp,
        tag: '',
        tags: '',
        payway: this.tags, // .join(','),
        payed: this.tags == '已支付費用' ? 1 : 0,
        price: this.price,
        formwhere: this.formwhere == '其他' ? this.formwhereother : this.formwhere,
        usepoint: 0,
        question: JSON.stringify(this.crouseInfo.question)
      }
      console.log('this.crouseInfo.question', this.crouseInfo.question);

      UserModel.regLesson(data, (res) => {
        this.$Message.destroy()
        this.$Message.success({
          content: '報名成功 #' + res[0].orderid
        })

        // if (data.payed == 0) {
        // 跳转到订单页
        this.$router.push({ path: '/result', query: { title: this.crouseInfo.title, needPay: this.price > 0 } })
        // this.$router.push({ name: 'orders', query: { phone1: this.phone1, phone2: this.phone2, phone3: this.phone3 } })
        console.log('res', res)
        // }
      }, (err) => {
        this.$Message.destroy()
        this.$Message.error({
          content: err || 'Something error'
        })
        console.log('err', err)
      })

    },
    selectChange (e, level) {
      this.price = 0
      if (level == 1) {
        this.list3 = []
        this.crouse_id = ''
        this.crouseInfo = {}
        this.select3 = ''
        for (var item of this.orgData) {
          if (item.s_id == e) {
            var arr = []
            for (var lesson of item.lesson) {
              arr.push({
                label: lesson.title, // + ((lesson.enroll_count != null && lesson.enroll_count != undefined) ? (' (已報名：' + lesson.enroll_count + '人)') : ''),
                value: lesson.l_id
              })
            }
            this.list2 = arr
            break
          }
        }
        if (this.list2.length == 1) {
          this.crouse_id = this.list2[0].value
          this.selectChange(this.crouse_id, 2)
        }
      } else if (level == 2) {
        this.select3 = ''
        for (var item of this.orgData) {
          if (item.s_id == this.select1) {
            for (var lesson of item.lesson) {
              if (lesson.l_id == e) {
                this.price = parseFloat(lesson.price)
                var arr = []
                for (var dd of lesson.date) {
                  var lt = dd.time.split('/')[0]
                  lt = lt.split(' ')[0]
                  console.log('lt', lt)
                  if (lt.length >= 8 && lt.indexOf('-') > 3 && lt.split('-').length == 3) {
                    var da = new Date(Number(lt.split('-')[0]), Number(lt.split('-')[1]) - 1, Number(lt.split('-')[2]));
                    console.log('da', da)
                    if (da.getTime() < (new Date).getTime() - 24 * 60 * 60 * 1000) {
                      continue
                    }
                  }
                  var la = ''
                  if (dd.enroll_count != undefined && dd.enroll_limit != undefined) {
                    if (dd.enroll_limit && dd.enroll_limit > 0) {
                      la = ' (已報名：' + dd.enroll_count + '人/' + dd.enroll_limit + '人)'
                    } else {
                      la = ' (已報名：' + dd.enroll_count + '人)'
                    }
                  }
                  arr.push({
                    label: dd.time + ' - ' + dd.teacher,
                    value: dd.time + ' - ' + dd.teacher,
                    enroll: la
                  })
                }

                this.list3 = arr
                if (this.list3.length == 1) {
                  this.select3 = this.list3[0].value
                  this.selectChange(this.select3, 3)
                }
                break
              }
            }
            break
          }
        }
      }
      if (this.select3 && this.select3.length > 0) {
        for (var o of this.list3) {
          if (o.value == this.select3) {
            this.select3enroll = o.enroll
            break
          }
        }
      } else {
        this.select3enroll = ''
      }

    }
  },
  watch: {
    crouse_id: function (val) {
      for (var s of this.orgData) {
        for (var l of s.lesson) {
          console.log(l.l_id, this.$route.params.lessonid);
          if (l.l_id == val) {
            this.crouseInfo = l
            this.theTitle = l.title + '報名表'
            if (this.crouseInfo.cover && this.crouseInfo.cover.indexOf('meiziopen.fpv.im') > 0 && this.crouseInfo.cover.indexOf('!750w') == -1) {
              this.crouseInfo.cover = this.crouseInfo.cover += '!750w'
            }
            return
          }
        }
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      console.log('$route.params', this.$route.params)
      console.log('$route.query', this.$route.query)

      if (this.$route.query.p == 1) {
        this.justpayed = true
      } else {
        this.justpayed = false
      }

      if (this.$route.params.appcode && this.$route.params.appcode.length > 2) {
        config.appcode = this.$route.params.appcode
        // if (config.appcode == 'jsg') {
        //   config.appcode = 'mmts'
        // }
      }

      if (this.$route.params.referrer && this.$route.params.referrer.length > 2) {
        this.isReferrer = true
        this.referrer = Base64.decode(this.$route.params.referrer)
        if (this.referrer == '80000001') {
          this.referrername = '01商學院'
          this.extReferrerPhone = '60807824'
        }
      } else {
        this.isReferrer = false
      }

      this.$Message.info({
        content: 'Loading...',
        duration: 0
      });
      UserModel.loadClasses((res) => {
        this.$Message.destroy()
        this.orgData = res
        var arr = []
        for (var item of this.orgData) {
          arr.push({
            label: item.title,
            value: item.s_id
          })
        }
        this.list1 = arr
        this.list2 = []
        this.list3 = []

        if (this.list1.length == 1) {
          this.select1 = this.list1[0].value
          this.selectChange(this.select1, 1)
        }

        if (this.$route.params.lessonid) {
          for (var s of this.orgData) {
            for (var l of s.lesson) {
              console.log(l.l_id, this.$route.params.lessonid);
              if (l.l_id == this.$route.params.lessonid) {
                this.select1 = s.s_id
                this.selectChange(this.select1, 1)

                this.crouse_id = l.l_id
                this.selectChange(this.crouse_id, 2)
                return
              }
            }
          }
        }
      }, (err) => {
        this.$Message.destroy()
        this.$Message.error({
          content: err || 'Something error'
        })
        console.log('err', err)
      })
    })
  },
}
</script>


<style>
@import "../assets/google.css";

#app {
  padding: 0px !important;
  max-width: none !important;
  background-color: rgb(255, 240, 217);
}

.home {
  padding: 0px;
}

.flex-align-con {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.title-text {
  margin-left: 5px;
  font-weight: 300;
  font-size: 41px;
  line-height: 25px;
  color: #000;
}

.select-item {
  margin-top: 10px;
}

.hairline {
  height: 1px;
  opacity: 0.5;
  background: #bebebe;
  margin: 20px -20px;
}

.black-title {
  margin: 0px -17px;
  margin-bottom: 20px;
  background: #9a9a9a;
  color: white;
  padding: 5px 20px;
}

.ivu-form-item {
  margin-bottom: 10px !important;
}

.ivu-form .ivu-form-item-label {
  font-size: 15px !important;
  line-height: 20px !important;
}

.graytip {
  font-size: 12px;
  color: #9a9a9a;
}

.wxtip {
  background: rgba(0, 0, 0, 0.8);
  text-align: right;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  /* display: none; */
}

.ivu-form .ivu-checkbox-group-item {
  display: block !important;
}

.ivu-form .ivu-radio-group-item {
  display: block !important;
  word-break: break-all;
  line-height: 32px;
  margin-left: 5px;
}

.cus-form {
  /* padding: 10px 10px; */
  margin-bottom: 20px;
}

.cus-form-item {
  margin-top: 14px;
  margin-bottom: 14px;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  padding: 20px;
}

.ivu-form .cus-form-item .ivu-form-item-label {
  display: block;
  float: none;
  text-align: left;
}

.cus-form-item-content {
  margin-top: -10px;
  color: #515a6e;
  font-size: 13px;
}
</style>
